import _ from 'lodash';
import { Plan, Response } from '../_data';
import { userService } from '../_services';


const actions = {

    homePlans(): Promise<Plan[]> {
        return userService.homePlans()
            .then(
                res => {
                    return res.data.data;
                }
            );
    },

    contactUs(_, formData): Promise<Response<any>> {
        return userService.contactUs(formData)
            .then(
                res => {
                    return res.data;
                }
            );
    },
};



export const account = {
    namespaced: true,
    actions,
};