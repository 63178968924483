import { InjectionKey } from 'vue';
import Vuex, { Store } from 'vuex';
import { account } from './account.module';





export const key: InjectionKey<Store<any>> = Symbol()

export const store = new Vuex.Store<any>({
    modules: {
        account,
    }
});