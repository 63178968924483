import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import App from './App.vue';
import { key, router, store } from './core';
library.add(fas, far, fab);
dom.watch();







const app = createApp(App)
app.use(router);
app.use(store, key);
app.use(VueAxios, axios);
app.component("font-awesome-icon", FontAwesomeIcon);
app.config.compilerOptions.isCustomElement = (tag) => {
    return tag.startsWith('eyu-');
};
app.mount('#app');