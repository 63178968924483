import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: 'landing' */ '@/views/landing/Landing.vue'),
    meta: {
      requiresAuth: false,
      title: 'Éyu'
    }
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

