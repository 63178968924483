import axios from 'axios';
import { Plan, Response, } from '../_data';
export const userService = {
    homePlans,
    contactUs
};

function homePlans(): Promise<Response<Plan[]>> {
    const url = process.env.VUE_APP_BASE_URL + "user/home/plans";
    return axios.get<Plan[]>(url);
}

function contactUs(formData): Promise<Response<any>> {
    const url = process.env.VUE_APP_BASE_URL + "user/contact-us";
    return axios.post(url, formData);
}
